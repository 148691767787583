import { _SET_CHANGED_PACKAGE, _SET_REQUIRED_REFRESH } from '../redux/PackageAction';

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_CODE_KEY = "code";
    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";

    static _API_INTERNAL_ERROR_CODE = 500;
    static _API_TEMPORARRY_REDIRECT_CODE = 307;
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary> 
export class ApiUrl {
    static _API_LOGIN = "/Account/Login";
    static _API_LOGOUT = "/Account/Logout";
    static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
    static _API_GET_ROLE_ACCESS_DATA = "/Account/GetRoleAccessData";
    static _API_GET_ROLE_BY_ID = "/Account/GetRoleById";
    static _API_GET_FIGHT_LIMIT_RELATED_DATA = "/Account/GetFightLimitRelatedData";
    static _API_UPDATE_USER_EAT_LIMIT = "/Account/UpdateUserEatLimit";
    static _API_GET_BETTING_AGENTS = "/Account/GetBettingAgents";
    static _API_CHANGE_PASSWORD = "/Account/ChangePassword";
    static _API_AGREE_TERMS_AND_CONDITIONS = "/Account/AgreeTermsAndConditions";
    static _API_GET_COMPANY_DETAIL_BY_SUPERADMIN = "/Account/GetCompanyDetailBySuperAdmin";

    // Account Management
    static _API_GET_USER_DETAIL_INIT = "/Account/GetUserDetailInit";
    static _API_CREATE_OR_UPDATE_ACCOUNT = "/Account/CreateOrUpdateUser";
    static _API_CREATE_OR_UPDATE_SUB_ACCOUNT = "/Account/CreateOrUpdateSubAccount";
    static _API_GET_USER_ACTIVITY_PERMISSION = "/Account/GetUserActivityPermission";

    static _API_GET_POOLS_SELECTION = "/Pools/GetPoolsSelection";
    static _API_SUBMIT_NEW_DRAW = "/Pools/SubmitNewDraw";
    static _API_SCHEDULE_NEXT_YEAR_DRAW = "/Pools/ScheduleSelectedYearDraw";
    static _API_GET_SCHEDULED_DRAW_BY_YEAR = "/Pools/GetScheduledDrawByYear";
    static _API_SUBMIT_EDITED_DRAW = "/Pools/SubmitEditedDraw";
    static _API_GET_PACKAGES = "/Configuration/GetPackages";
    static _API_CREATE_OR_UPDATE_PACKAGE = "/Configuration/CreateOrUpdatePackage";
    static _API_GET_PACKAGE_DETAIL = "/Configuration/GetPackageDetail";
    static _API_CREATE_OR_UPDATE_MASTER_TEMPLATE = "/Configuration/CreateOrUpdateMasterTemplate";
    static _API_GET_MASTER_TEMPLATES = "/Configuration/GetMasterTemplates";
    static _API_GET_MASTER_TEMPLATE_ITEMS = "/Configuration/GetMasterTemplateItems";
    static _API_GET_MASTER_TEMPLATE_ITEM_CATEGORIES = "/Configuration/GetMasterTemplateItemCategories";
    static _API_GET_API_LOG = "/Configuration/GetApiLog";
    static _API_GET_API_REQUEST_LOG = "/Configuration/GetApiRequestLog";

    static _API_GET_POOLS_RESULT = "/Result/GetPoolResult";
    static _API_SAVE_POOLS_RESULT = "/Result/SavePoolResult";
    static _API_BLOCK_REPORT = "/Result/BlockReport";
    static _API_GET_REPORT_ACCESS_VALUE = "/Result/GetReportAccessValue";
    static _API_UPDATE_CUTOFF_TIME = "/Pools/UpdateCutoffTime";
    static _API_VALIDATE_RESULT = "/Result/ValidateResult";
    static _API_UPDATE_DRAW_PAYMENT_DETAIL = "/Result/UpdateDrawPaymentDetail";
    static _API_UPDATE_CREDIT_RELEASEL = "/Result/UpdateCreditRelease";
    static _API_GET_USERS_ROLE = "/Setting/GetRoles";
    static _API_GET_ACTIVITIES = "/Setting/GetActivities";
    static _API_GET_PERMISSIONS = "/Setting/GetPermissions";
    static _API_GET_ROLE_PERMISSION = "/Setting/GetActivityPermissionSetting";
    static _API_GET_ROLE_ACTIVITY_PERMISSION = "/Setting/GetRoleActivityPermission";

    static _API_GET_ANNOUNCEMENT = "/Configuration/GetAnnouncement";
    static _API_ADD_CREATE_OR_UPDATE_ANNOUNCEMENT = "/Configuration/CreateOrUpdateAnnouncement";

    static _API_GET_SYSTEM_SETTING = "/Configuration/GetSystemSetting";
    static _API_UPDATE_SYSTEM_SETTING = "/Configuration/UpdateSystemSetting";
    static _API_GET_NUMBER_QUEUES = "/Configuration/GetNumberQueues";
    static _API_DELETE_NUMBER_QUEUE = "/Configuration/DeleteNumberQueue";

    static _API_GET_CURRENCY_SETTING = "/Configuration/GetCurrencySetting";
    static _API_UPDATE_CURRENCY_SETTING = "/Configuration/UpdateCurrencySetting";

    static _API_SUBMIT_ASSIGN_ROLES = "/Setting/AssignRoles";
    static _API_SUBMIT_CREATE_UPDATE_ROLE = "/Setting/CreateOrUpdateRole";
    static _API_GET_PERMUTATION_TYPE = "/Configuration/GetPermutationType";
    static _API_GET_RED_NUMBER = "/Configuration/GetRedNumber";
    static _API_CREATE_OR_UPDATE_RED_NUMBER = "/Configuration/CreateOrUpdateRedNumber";
    static _API_DELETE_RED_NUMBER = "/Configuration/DeleteRedNumber";

    static _API_GET_POOL_SCHEDULE_BY_TOTAL_DAY = "/Pools/GetPoolScheduleByDay";
    static _API_GET_TIP_SOURCE_BY_KEYWORD = "/Configuration/GetTipSourceByKeyword";

    static _API_PLACE_EXPRESS_ORDER = "/Bet/PlaceExpressOrder";
    static _API_PLACE_WEB_ORDER = "/Bet/PlaceWebOrder";
    static _API_VOID_TICKET = "/Bet/VoidTicket";
    static _API_GET_BET_RECEIPT = "/Bet/GetBetReceipt";
    static _API_BULK_VOID_TICKET = "/Bet/BulkVoidTicket";

    static _API_GET_USER_LISTING = "/Account/GetUserListing";
    static _API_UPDATE_USER_CREDIT_LIMIT = "/Account/UpdateUserCreditLimit";
    static _API_FIXED_RED_PACKAGE = "/Account/FixIsRedPackage";
    static _API_GET_RESERVE_NAME_PREFIX = "/Account/GetReserveNamePrefix";
    static _API_ADD_RESERVE_NAME_PREFIX = "/Account/CreateOrUpdateReserveNamePrefix";
    static _API_GET_API_ACCOUNT = "/Account/GetApiAccount";
    static _API_GENERATE_API_CODE = "/Account/GenerateApiCode";
    static _API_ADD_API_ACCOUNT = "/Account/CreateOrUpdateApiAccount";
    static _API_CLONE_ACCOUNT = "/Account/CloneAccount";
    static _API_GET_SUB_ACCOUNT_LISTING = "/Account/GetSubAccountListing";
    static _API_GET_HOUSE_USER_LISTING = "/Account/GetHouseUsers";

    static _API_GET_PAYMENT_TYPE = "/Payment/GetPaymentType";
    static _API_MAKE_PAYMENT = "/Payment/MakePayment";
    static _API_GET_PAYMENT_HISTORY = "/Payment/GetPaymentHistory";

    static _API_INIT_DRAW_PAYMENT = "/Payment/InitDrawPayment";
    static _API_SAVE_DRAW_PAYMENT = "/Payment/SaveDrawPayment";
    static _API_GET_DRAW_PAYMENT_HISTORY = "/Payment/GetDrawPaymentHistory";

    static _API_GET_USER_DETAIL_BY_ID = "/Account/GetUserDetailsById";
    static _API_GET_USER_DETAIL_BY_USERNAME = "/Account/GetUserDetailByUsername";
    static _API_ADMIN_UPDATE_USER_CREDIT_LIMIT = "/Account/AdminUpdateUserCreditLimit";

    static _API_GET_SALES_SUMMARY = "/Report/GetSalesSummary";

    static _API_GET_TICKET = "/Report/GetTicket";
    static _API_SEND_SMS_RECEIPT = "/Report/SendSmsReceipt";
    static _API_GET_MAP_REPORT = "/Report/GetMapReport";
    static _API_GET_SELF_PAYOUT_REPORT = "/Report/GetSelfPayoutReport";
    static _API_GET_PAYOUT_REPORT = "/Report/GetPayoutReport";
    static _API_GET_SALES_STATISTIC = "/Report/GetSalesStatistic";
    static _API_GET_PAYOUT_STATISTIC = "/Report/GetPayoutStatistic";
    static _API_GET_PAYOUT_TABLE = "/Report/GetPayoutTable";
    static _API_GET_STAKE_HOLDER_REPORT = "/Report/GetStakeHolderReport";
    static _API_GET_WIN_LOSS_REPORT = "/Report/GetWinLossReport";
    static _API_GET_WIN_LOSS_3_SECTION_REPORT = "/Report/GetWinLoss3SectionsReport";
    static _API_GET_WINNING_REPORT = "/Report/GetWinningReport";
    static _API_GET_USER_EAT_DATA = "/Report/GetUserEatData";
    static _API_GET_INIT_REPORT = "/Report/InitReport";
    static _API_GET_DETAIL_JACKPOT = "/Report/GetDetailJackpot";
    static _API_GET_SMS_COUNT_REPORT = "/Report/GetSmsCountReport";

    static _API_GET_DIRECT_DOWNLINES = "/Account/GetDirectDownlines";
    static _API_GET_TARGET_LINES = "/Account/GetTargetLines";

    static _API_GET_RECEIPT_STRING = "/Setting/GetReceiptStringByTicketXId";
    static _API_UPDATE_RECEIPT_STRING = "/Setting/UpdateTicketReceiptString";

    static _API_TRIGGER_WINNING_REPORT = "/Result/InsertWinners";
    static _API_CLAIM_PRIZES = "/Payment/ClaimPrizes";
    static _API_GET_BACKUP_STAGE = "/Report/GetBackupStage";
    static _API_PRE_INSERT_BACKUP_STAGE = "/Setting/PreInsertBackUpStage";
    static _API_RESETTLE_SEAMLESS_TICKET = "/Bet/ResettleSeamless";
    static _API_GET_REMOTE_BET_AGENT = "/Configuration/GetRemoteBetAgents";
    static _API_MANAGE_REMOTE_BET_AGENT = "/Configuration/ManageRemoteBetAgent";
    static _API_GET_REMOTE_API_BALANCE = "/Configuration/GetRemoteApiBalance";
    static _API_SET_REMOTE_API_BALANCE = "/Configuration/SetRemoteApiBalance";
    static _API_GET_COMM_STATUS = "/Configuration/GetCommStatus";
    static _API_GET_AUTO_TICKET = "/Report/GetAutoTicket";
    static _API_VOID_AUTO_TICKET = "/Report/VoidAutoTicket";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _URL_MAIN = "/";
    static _URL_LOGIN = "/login";
    static _URL_TERMS_AND_CONDITIONS = "/terms-and-conditions";
    static _URL_DASHBOARD = "/dashboard";
    static _URL_HELP = "/help";
    static _URL_ACCOUNT_MANAGEMENT = "/account/manage";
    static _URL_ACCOUNT_DETAIL = "/account/detail";
    static _URL_SUB_ACCOUNT_DETAIL = "/sub-account/detail";
    static _URL_COMPANY_MANAGEMENT = "/company/manage";
    static _URL_NUMBER_MEANING = "/number-meaning";
    static _URL_POOL_SCHEDULE = "/admin-setting/pool-schedule";
    static _URL_MANAGE_PACKAGE = "/package/manage";
    static _URL_PACKAGE_DETAIL = "/package/detail";
    static _URL_RESULT = "/result";
    static _URL_CUTOFF_TIME = "/adminSetting/cutoff-time";
    static _URL_ROLES = "/roles/manage";
    static _URL_FIGHT_LIMIT = "/manage-fight-limit";
    static _URL_ANNOUNCEMENT = "/admin-setting/annoucenment-setting";
    static _URL_SYSTEM_SETTING = "/admin-setting/system-setting";
    static _URL_CURRENCY = "/admin-setting/currency";
    static _URL_RED_NUMBER = "/admin-setting/rednumber";
    static _URL_MANAGE_MASTER_TEMPLATE = "/admin-setting/manage-master-template";
    static _URL_WEB_ORDER = "/betting/web-order";
    static _URL_WEB_ORDER_TWO = "/betting/web-order2";
    static _URL_EXPRESS_BETTING = "/order/express-betting";
    static _URL_RESERVE_NAME_PREFIX = "/account/reserve-name";
    static _URL_CREATE_API_ACCOUNT = "/account/create-api-account";
    static _URL_CLONE_ACCOUNT = "/account/clone-account";
    static _URL_PAYMENT = "/payment";
    static _URL_DRAW_PAYMENT = "/payment/draw-payment";
    static _URL_SALES_SUMMARY_REPORT = "/report/sales-summary";
    static _URL_MAP_REPORT = "/report/map-report";
    static _URL_INBOX = "/inbox";
    static _URL_PAYOUT_REPORT = "/report/payout-report";
    static _URL_SALES_STATISTIC_REPORT = "/report/sales-statistic-report";
    static _URL_PAYOUT_STATISTIC_REPORT = "/report/payout-statistic-report";
    static _URL_PAYOUT_TABLE_REPORT = "/report/payout-table-report";
    static _URL_STAKE_HOLDER_REPORT = "/report/stakeholder-report";
    static _URL_WIN_LOSS_REPORT = "/report/win-loss-report";
    static _URL_WIN_LOSS_3_SECTION_REPORT = "/report/win-loss-2-report";
    static _URL_WINNING_REPORT = "/report/winning-report";
    static _URL_MANAGE_PROFILE = "/manage-profile";
    static _URL_WIN_LOSS_DETAIL = "/winloss-detail";
    static _URL_JACKPOT_DETAIL = "/jackpot-detail";
    static _URL_API_LOG = "/api-log";
    static _URL_API_REQUEST_LOG = "/api-request-log";
    static _URL_ADMIN_TOOL = "/admin-tool";
    static _URL_SELF_WIN_LOSS_DETAIL = "/self-winloss-detail";
    static _URL_STRIKE_REPORT = "/report/strike-report";
    static _URL_AUTO_TICKET = "/betting/auto-ticket";
}

/// <summary>
/// Author : -
/// </summary>
export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _DELETED = 2;
}

/// <summary>
/// Author : CK
/// this value needed to be tally to value in server, do not change recklessly
/// </summary> 
export class AccessRight {
    static _DOWNLINE_PERMISSION = "Downline";
    static _MAIN_ACCOUNT_PERMISSION = "Main Account";
    static _BET_SCHEDULE_PERMISSION = "Bet Schedule";
    static _BET_PERMISSION = "Bet";
    static _BET_HISTORY_PERMISSION = "Bet History";
    static _COMPANY_PERMISSION = "Company";
    static _FIGHT_LIMIT_PERMISSION = "Fight Limit";
    static _RED_NUMBER_PERMISSION = "Red Number";
    static _STAKE_HOLDER_REPORT_PERMISSION = "Stakeholder Report";
    static _INBOX_REPORT_PERMISSION = "Inbox Report";
    static _PROFIT_LOSS_REPORT_PERMISSION = "Profit Loss Report";
    static _DAILY_SALES_REPORT_PERMISSION = "Daily Sales Report";
    static _GROUP_SALES_REPORT_PERMISSION = "Group Sales Report";
    static _MAP_REPORT_PERMISSION = "Map Report";
    static _TOTAL_BET_REPORT_PERMISSION = "Total Bet Report";
    static _TOTAL_STAKE_REPORT_PERMISSION = "Total Stake Report";
    static _PAYOUT_REPORT_PERMISSION = "Payout Report";
    static _WINNING_REPORT_PERMISSION = "Winning Report";
    static _GD_WIN_LOSS_REPORT_PERMISSION = "GD Win Loss Report";
    static _PAYMENT_REPORT_PERMISSION = "Payment Report";
    static _VIEW_PERMISSION = "1";
    static _CREATE_PERMISSION = "2";
    static _EDIT_PERMISSION = "3";
    static _DELETE_PERMISSION = "4";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _CHINESE = "cn";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "bm";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
}

/// <summary>
/// Author: -
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
    static _MIN_KEY = "min";
    static _MAX_KEY = "max";
    static _PATTERN = "pattern";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author: CK
/// </summary>
export class ValidationPattern {
    static _emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static _phoneNumberRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
    static _numberRegex = /^([1-9]|[1-9][0-9]|[1-9][0-9]*)$/;
    static _decimalRegex = /^[1-9][0-9]*(\.\d{1,2})?$|^[0](\.\d{1,2})?$/;
    static _digitRegex = /^\d+$/;
    static _twoDecimalAndLargerThanZero = /^(?!00?\.00$)\d{1,2}(?:\.\d{1,2})?$/;
}

/// <summary>
/// Author: CK
/// </summary>
export const _SELECT_STATUS_OPTIONS = [
    { value: false, label: "DISABLED" },
    { value: true, label: "ENABLED" }
]

/// <summary>
/// Author: CK
/// </summary>
export class ReceiptSetting {
    static _NORMAL = 1;
    static _REVERSE = 2;
}

/// <summary>
/// Author: CK
/// </summary>
export class ReceiptFormat {
    static _DEFAULT = { id: 1, label: "default" };
    static _4D218 = { id: 2, label: "218" };
    static _ABS333 = { id: 3, label: "ABS333" };
}

/// <summary>
/// Author: CK
/// </summary>
export const _BOX_IBOX_OPTIONS = [
    { value: "*/**", label: "*/**" },
    { value: "**/*", label: "**/*" }
]

/// <summary>
/// Author: CK
/// </summary>
export class Role {
    static _SUPER_ADMIN = 1;
    static _HOUSE = 2;
    static _COMPANY = 3;
    static _MULTILEVEL = 4;
    static _SHAREHOLDER = 5;
    static _ADMIN = 6;
    static _SUPER_SENIOR = 7;
    static _SENIOR = 8;
    static _MASTER = 9;
    static _AGENT = 10;
    static _PLAYER = 11;
}

/// <summary>
/// Author: Wind
/// Remark: Do not change, used to map with DB
/// </summary>
export class Pool {
    static _MAGNUM = 1;
    static _PMP = 2;
    static _TOTO = 3;
    static _SINGAPORE = 4;
    static _SABAH = 5;
    static _STC = 6;
    static _SARAWAK = 7;
    static _GD = 8;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class PackageType {
    static _NORMAL_PACKAGE = 0;
    static _RED_PACKAGE = 1;
    static _GD_PACKAGE = 2;
    static _NINE_LOTTO_PACKAGE = 3;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class EatMethod {
    static _AMOUNT_BASE = 1;
    static _TOTAL_BASE = 2;
    static _GROUP_BASE = 3;
}

/// <summary>
/// Author: CK
/// </summary>
export class AutoTransferFrequency {
    static _WEEKLY = 0;
    static _DAILY = 1;
}

/// <summary>
/// Author: CK
/// </summary>
export class WeekDay {
    static _SUNDAY = 0;
    static _MONDAY = 1;
    static _TUESDAY = 2;
    static _WEDNESDAY = 3;
    static _THURSDAY = 4;
    static _FRIDAY = 5;
    static _SATURDAY = 6;
}

/// <summary>
/// Author: Wind
/// Remark: Do not change, used to map with DB
/// </summary>
export class RedNumberType {
    static _NORMAL_RED_NUMBER = 1;
    static _4P = 2;
    static _6P = 3;
    static _12P = 4;
    static _24P = 5;
}

/// <summary>
/// Author: CK
/// </summary>
export const _MASTER_PACKAGE_PARENT_ID = 0;

/// <summary>
/// Author: CK
/// </summary>
export class LocalStorageKey {
    static _CHANGED_PACKAGE = "changedPackage";
    static _REQUIRED_REFRESH = "requiredRefresh";
    static _PENDING_TNC_AGREEMENT = "pendingTNCAgreement";
}

/// <summary>
/// Author: CK
/// </summary>
export const _LOCAL_STORAGE_DISPATCH = [
    { key: LocalStorageKey._CHANGED_PACKAGE, dispatchKey: _SET_CHANGED_PACKAGE },
    { key: LocalStorageKey._REQUIRED_REFRESH, dispatchKey: _SET_REQUIRED_REFRESH }
]

/// <summary>
/// Author: Wind
/// Remark: Do not change, used to map with DB
/// </summary>
export class SettingKey {
    static _AGENT_MAX_DISCOUNT_PERCENTAGE = "agentMaxDiscountPercentage";
    static _PAYBACK_COMM_RATE = "paybackCommissionRate";
    static _LUCKY_BET_PER_AMOUNT = "luckyNumberPerBetAmount";
    static _SCRAPE_RESULT_TIME = "scrapeResultTime";
    static _MAX_LINE_BET = "maxLineBetInUI";
    static _DAILY_POOL = "dailyPool";
}

/// <summary>
/// Author: Wind
/// </summary>
export class EatType {
    static _2D = 'd2';
    static _3D = 'd3';
    static _4D = 'd4';
    static _5D = 'd5';
    static _6D = 'd6';
}

/// <summary>
/// Author: Wind
/// </summary>
export class BetType {
    static _NORMAL = 1;
    static _BOX = 2;
    static _IBOX = 3;
    static _PH = 4;
    static _PB = 5;
}

/// <summary>
/// Author: Wind
/// </summary> 
export class PaymentType {
    static _TRANSFER_CREDIT = 1;
    static _RECEIVE_CREDIT = 2;
}

/// <summary>
/// Author: Wind
/// </summary> 
export class OrderMethod {
    static _MULTIPY = "*";
    static _DIVIDE = "/";
}

/// <summary>
/// Author: YJ
/// </summary> 
export const _BET_OPTIONS = [
    { value: -1, label: "ALL" },
    { value: 0, label: "NORMAL_BET" },
    { value: 1, label: "RED_BET" }
]

/// <summary>
/// Author : Wind
/// </summary>
export class TicketStatus {
    static _ACTIVE = 0;
    static _PROCESSED = 1;
    static _CANCELLED = 2;
    static _ERROR = 99;
}

/// <summary>
/// Author: Andrew
/// </summary>
export class PrizePlace {
    static _FIRST = "First";
    static _SECOND = "Second";
    static _THIRD = "Third";
    static _FOURTH = "Fourth";
    static _FIFTH = "Fifth";
    static _SIXTH = "Sixth";
    static _STARTER = "Starter";
    static _CONSOLATION = "Consolation";
    static _COMMISSION = "Comm";
}

/// <summary>
/// Author: CK
/// Prize category Id mapped to db
/// </summary>
export class PrizeCategory {
    static _FIRST = 1;
    static _SECOND = 2;
    static _THIRD = 3;
    static _FOURTH = 4;
    static _FIFTH = 5;
    static _SIXTH = 6;
    static _STARTER = 7;
    static _CONSOLATION = 8;
}

/// <summary>
/// Author: YJ
/// </summary>
export class EatTypeGroupId {
    static _2D = 1;
    static _3D = 2;
    static _4D = 3;
    static _5D = 4;
    static _6D = 5;
}

/// <summary>
/// Author: CK
/// </summary>
export const _SELECT_DEBOUNCE_MS = 50;

/// <summary>
/// Author: A
/// </summary>
export class REGION {
    static _SWC = "SWC";
    static _REJANG = "Rejang";
    static _SY = "SY"
}

/// <summary>
/// Author: Wind
/// </summary>
export class RegionId {
    static _SWC = 1;
    static _REJANG = 2;
}